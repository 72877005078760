<template>
  <v-app>
    <v-container>
      <v-row class="mt-10">
        <v-col>
          <h1 class="text-left mb-8">
            Request data
          </h1>
          <p class="text-left text-justify">
            The STRESS-NL database is governed by a consortium agreement,
            allowing data to be accessed by consortium members and external
            parties (non-consortium members). Consortium members and external
            parties can gain access to anonymized individual participant data
            via an analysis plan submitted to the STRESS-NL Steering Committee.
            The Steering Committee will discuss and approve data analysis plans.
            Participation and data contribution of individual stress studies are
            based on an opt-in principle (i.e. studies have to agree to
            contribute). For non-consortium members, there will be a limited fee
            of 500€ for approved analysis plans to finance database updates and
            maintenance.
          </p>
          <p class="text-left">
            The procedure for gaining data access is as follows:
          </p>
          <p class="text-left pl-6 text-justify">
            1. Meta-data of individual studies can be found and searched on the
            <router-link to="./data">data page</router-link>. A query menu on
            the left side of the page enables users to define specific queries
            and retrieve summary statistics and meta-data in real time.
          </p>
          <p class="text-left pl-6 text-justify">
            2. After a final query has been specified and saved in the query
            menu, the user can press the "request data" button. This will
            download an analysis plan with a unique requestID already filled in,
            and a table (.csv file) with all available metadata.
          </p>
          <p class="text-left pl-6 text-justify">
            3. Fill in the analysis plan, leaving the filled in requestID
            intact. The requestID is also the name of the .csv-file that is
            downloaded after pressing the "request data" button. This .csv-file
            can be used for further inspection of avaialable metadata.
          </p>
          <p class="text-left pl-6 text-justify">
            4. Send us both the filled in analysis plan AND the .csv-file (with
            unaltered, orignal filename) by e-mail, by clicking the button below
            (add both files as attachment). Please mention your requestID in the
            e-mail as well. In order to avoid duplicate work, please first
            consult
            <router-link to="./plans">the list of analysis plans</router-link>
            that are already approved, before you submit an anlysis plan of your
            own.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="text-left text-justify">
            After checking the data and verifying with you, we will be able to
            share the full queried anonymized data with you. You will also get
            additional access to contributing PIs, if more data is needed.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            class="mb-5 mt-10"
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="mailRequest"
          >
            E-mail Request
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {};
  },
  computed: {},
  methods: {
    mailRequest() {
      window.location.href =
        "mailto:stresseu@amsterdamumc.nl?subject=Data request for Stress-EU database&body=My requestID is: ";
    },
  },
};
</script>
